import React from 'react'
import styled from 'styled-components'
import HeadlineTwo from '../headlinetwo'
import HeadlineExtra from '../headlineextra'
import Underline from '../underline'
import Copytext from '../copytext'
import TestiOne from '../../images/testione.png'
import TestiTwo from '../../images/testitwo.png'

const TestimonialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  flex-wrap: wrap;
`
const Testimonial = styled.div`
  width: 570px;
  padding: 80px;
  background-color: #f7f7f7;
  display: flex;
  padding: 40px;
`

const TestimonialLeftCol = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
`

const TestimonialRightCol = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
`

const Image = styled.img`
  margin: 0;
  width: 92px;
  height: 92px;
`

const TestimonialTitle = styled.div`
  color: #d3a04e;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-transform: uppercase;
`

const JobTitle = styled.div`
  color: #777777;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  line-height: 28px;
  text-transform: uppercase;
`

const TestiComment = styled.div`
  max-width: 352px;
  color: #898989;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
`

export default function Testimonials() {
  return (
    <div
      style={{
        margin: '0 auto',
        maxWidth: 1280,
        padding: '1.45rem 1.0875rem 0',
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      <HeadlineTwo>
        <HeadlineExtra>Kunden</HeadlineExtra> Meinungen
      </HeadlineTwo>
      <Underline />
      <Copytext>
        Anbei ein Auszug unser langjährigen Kundenbeziehungen, wir freuen uns
        auf Sie und die neuen Herausforderung
      </Copytext>
      <TestimonialWrapper>
        <Testimonial>
          <TestimonialLeftCol>
            <Image src={TestiOne} alt="" />
          </TestimonialLeftCol>
          <TestimonialRightCol>
            <TestimonialTitle>Felix Eigenbrodt</TestimonialTitle>
            <JobTitle>ceo, SB Konzept GmbH</JobTitle>
            <TestiComment>
              Super kompetente Steuerkanzlei. Habe meine
              Einkommensteuererklärung abgegeben und hatte Sie auch am nächsten
              Tag wieder in der Post.
            </TestiComment>
          </TestimonialRightCol>
        </Testimonial>
        <Testimonial>
          <TestimonialLeftCol>
            <Image src={TestiTwo} alt="" />
          </TestimonialLeftCol>
          <TestimonialRightCol>
            <TestimonialTitle>Konstanze Schäfer</TestimonialTitle>
            <JobTitle>ceo, Pom Pom AG</JobTitle>
            <TestiComment>
              Einfach toll wenn man eine freundliche Steuerberatung findet die
              einem die schweren Dinge auch leicht erklären können.
            </TestiComment>
          </TestimonialRightCol>
        </Testimonial>
      </TestimonialWrapper>
    </div>
  )
}
